#realisations {
    background-color: $darkblue;

    @include media-breakpoint-up(lg) {
        padding-bottom: 5rem;
    }


    .main__row__responsive {
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: unset;

            &:nth-child(odd) {
                flex-direction: row-reverse;
            }
        }
    }

    .main__col--responsive {
        display: flex;

        @include media-breakpoint-up(md) {
            &.measy {
                justify-content: flex-end;
            }

            &.le-potager-regal {
                justify-content: flex-start;
            }
        }
    }

    .article--desktop .article__container {
        position: relative;

        &:hover {
            cursor: pointer;
        }

        .caption {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.article--reduced:hover {
            .caption {
                transition: width 0.5s;
                width: 7rem;
            }

            p {
                font-weight: bold;
                opacity: 1;
                margin: 0;
            }

            img {
                filter: brightness(0.6);
            }

            .caption__button__container {
                background-color: $darkblue;
                width: 100%;
                height: 6rem;
                text-align: center;
                border-bottom-left-radius: 60px;
                border-top-left-radius: 60px;
                box-shadow: 0px 0px 22px 2px #000000;
                border: rgba(0, 0, 0, 0.5) solid 1px;
                border-right: none;
            }

            .caption__button__content {
                height: 6rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.article--reduced .article__description {
            display: none;
            opacity: 0;
        }

        &.article--deployed {
            .caption {
                transition: width 0.5s;
                width: 100%;
            }

            .caption__button__container {
                background-color: $darkblue;
                width: 100%;
                height: 6rem;
                text-align: center;
                border-bottom-left-radius: 60px;
                border-top-left-radius: 60px;
                animation: captionHeight 0.5s;
                animation-delay: 0.5s;
                animation-fill-mode: forwards;

                p {
                    display: none;
                }
            }

            .article__description {
                transition-delay: 1s;
                transition-duration: 1s;
                transition-property: opacity display;
                display: flex;
                opacity: 0;
                animation: ulShow 0.5s;
                animation-delay: 1s;
                animation-fill-mode: forwards;

                &:hover {
                    cursor: auto;
                }

                h3 {
                    margin: 0;
                    padding-bottom: 2rem;

                    &.visit-url {
                        padding-bottom: 0;
                    }
                }

                li {
                    padding: 0.2rem 1rem;
                    list-style-type: disc;
                    text-align: start;
                }

                .visit-url {
                    a {
                        color: white;
                        text-decoration: underline;
                        font-size: 1.5rem;
                    }
                }
            }

            .icon__container {
                position: relative;
                min-width: 3rem;
                width: 3rem;
                animation: bounce-in-left 1s 1s both;

                img {
                    object-fit: contain;
                    object-position: center;
                    width: 100%;
                    height: auto;
                    transform: rotate(180deg);
                }
            }

            .article__footer {
                border-top: $darkyellow 1px dashed;
                padding-top: 2rem;

                i {
                    margin: 0;
                    display: inline;
                    color: white;
                    font-size: 1.5rem;
                    padding-right: 2rem;
                }
            }

            img, i {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .article--mobile .article__container {
        position: relative;

        .article__description {
            h3 {
                margin: 0;
                padding: 2rem 0;
            }

            li {
                margin-left: 0.5rem;
                padding: 0.3rem 1.5rem;
                list-style-type: disc;
                text-align: start;
            }
        }
    }

    p {
        font-weight: bold;
        opacity: 0;
        color: $yellow;
    }

    img {
        width: 100%;
    }

    h1 {
        padding: 6rem;

        &::before {
            position: absolute;
            content: "";
            width: 1px;
            background-color: rgba(255, 255, 255, 0.6);
        }

        &::before {
            top: -$lineHeight;
            height: $lineHeight * 2;
        }
    }

    h2 {
        margin-bottom: 1rem;
        justify-content: flex-start;

        &::after {
            margin: 1rem 0;
            content: "";
            width: 100%;
            height: 2px;
            background-color: $yellow;
        }
    }

    ul {
        margin-bottom: 1rem;
    }
}
