/* COLORS */
$yellow: rgb(241, 173, 33);
$darkyellow: rgb(206, 140, 9);
$darkblue: rgb(7, 10, 37);
$green: rgb(31, 158, 82);

/* FONT */
$h1SizeDesktop: 2.5rem;
$h1SizeMobile: 3rem;
$h1SizeTablet: 3.2rem;

$h2SizeDesktop: 2rem;
$h2SizeMobile: 2.5rem;
$h2SizeTablet: 2.7rem;

$h3SizeDesktop: 1.5rem;
$h3SizeMobile: 2rem;
$h3SizeTablet: 2.2rem;

$fontSizeDesktop: 1rem;
$fontSizeMobile: 1.5rem;
$fontSizeTablet: 1.5rem;

/* LINES */

$lineHeight: 3rem;
