#competences {
    background-color: $darkblue;
    padding: 5rem 0;

    @include media-breakpoint-up(lg) {
        padding-bottom: 5rem;
    }


    .main__row__responsive {
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: unset;
        }
    }

    .line {
        width: 0.05rem;
        background-color: white;
        margin: 0 4rem;
    }

    .round {
        $roundWidth: 3rem;

        width: $roundWidth;
        height: $roundWidth;
        border-radius: 100%;
        border: 0.15rem white solid;
        background-color: $yellow;
        margin-left: 4rem + 0.025rem - $roundWidth / 2;
    }

    .main__content {
        @include media-breakpoint-up(lg) {
            margin-bottom: 3rem;
        }
    }

    .row__content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .skill__title {
            width: auto;
            margin: 0;
            vertical-align: text-bottom;
            padding: 0.5rem 0;
            text-align: center;
        }

        .skill__image {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            width: initial;

            .skill__title {
                text-align: initial;
                margin-right: 2rem;
            }

            .skill__image {
                justify-content: flex-start;
            }
        }
    }

    .icon__container {
        display: flex;
        width: 5rem;
        padding: 0 1rem 1rem 1rem;

        img {
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 3rem;

            &.node {
                transform: scale(1.5);
            }

            &.mongodb, &.html, &.css {
                transform: scale(1.3);
            }
        }

        .img__caption {
            opacity: 0;
            width: 10rem;
            color: #fff;
            text-align: center;
            position: absolute;
            z-index: 1;
            bottom: -2rem;
            transform: translateX(-3.6rem);
            font-weight: lighter;
            font-size: 1rem;
        }

        &:hover .img__caption {
            transition: opacity 0.5s;
            opacity: 1;
        }
    }

    h1 {
        display: flex;
        justify-content: center;
        padding-bottom: 6rem;

        @include media-breakpoint-up(lg) {
            display: initial;
            text-align: initial;
            padding-bottom: 0;
        }
    }
}
