#banner {
    .img__container {
        z-index: -1;
        position: absolute;
        height: 100vh;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgb(1, 16, 161), rgb(63, 69, 149));
            opacity: 0.2;
            top: 0;
            left: 0;
        }
    }

    .subtitle {
        font-size: 2.2rem;
        font-weight: lighter;
    }

    .content {
        width: 70%;

        p {
            font-size: 1.5rem;
        }

        &::before {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
            transform: translate(0, -1rem);
        }
    }
}
