body {
    font-family: "Roboto", sans-serif;
    font-size: $fontSizeMobile;

    @include media-breakpoint-up(md) {
        font-size: $fontSizeTablet;
    }


    @include media-breakpoint-up(lg) {
        font-size: $fontSizeDesktop;
    }
}

strong {
    color: $yellow;
}

h1, h2, h3, p, li {
    color: white;
}

h1 {
    font-weight: bold;
    font-size: $h1SizeMobile;

    mark {
        position: relative;
        padding: 0;
        background-color: transparent;
        color: white;
        z-index: 0;

        &:before {
            z-index: -1;
            content: "";
            position: absolute;
            width: 100%;
            height: 1.2rem;
            background-color: $darkyellow;
            left: 0.3rem;
            bottom: 0;
        }
    }

    @include media-breakpoint-down(321px) {
        font-size: 2.6rem;
    }


    @include media-breakpoint-up(md) {
        font-size: $h1SizeTablet;
    }


    @include media-breakpoint-up(lg) {
        font-size: $h1SizeDesktop;
    }
}

h2 {
    font-weight: bold;
    font-size: $h2SizeMobile;

    @include media-breakpoint-up(md) {
        font-size: $h2SizeTablet;
    }


    @include media-breakpoint-up(lg) {
        font-size: $h2SizeDesktop;
    }
}

h3 {
    font-size: 1.6rem;
    width: 100%;

    mark {
        position: relative;
        padding: 0;
        background-color: transparent;
        color: white;
        z-index: 0;

        &:before {
            z-index: -1;
            content: "";
            position: absolute;
            width: 100%;
            height: 0.8rem;
            background-color: $darkyellow;
            left: 0.3rem;
            bottom: 0;
        }
    }
}

ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
}

section, footer {
    overflow: hidden;
}
