#services {
    background-color: $darkblue;
    padding: 5rem 0rem;

    .main__row__responsive {
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            align-items: unset;
        }
    }

    .line {
        width: 0.05rem;
        background-color: white;
        margin: 0 4rem;
    }

    .round {
        $roundWidth: 3rem;

        width: $roundWidth;
        height: $roundWidth;
        border-radius: 100%;
        border: 0.15rem white solid;
        background-color: $yellow;
        margin-left: 4rem + 0.025rem - $roundWidth / 2;
    }

    .main__content {
        @include media-breakpoint-up(lg) {
            margin-bottom: 3rem;
        }
    }

    .icon__container {
        min-width: 3rem;
        width: 3rem;

        @include media-breakpoint-up(lg) {
            min-width: 5rem;
            width: 5rem;
        }


        img {
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: auto;
        }
    }

    h1 {
        display: flex;
        justify-content: center;
        padding-bottom: 6rem;

        @include media-breakpoint-up(lg) {
            display: initial;
            text-align: initial;
            padding-bottom: 0;
        }
    }
}
