@keyframes captionHeight {
    from {
        height: 0%;
    }

    to {
        height: 100%;
        border-radius: 0;
    }
}

@keyframes ulShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes bounce-in-left {
    0% {
        transform: translateX(-40px);
        animation-timing-function: ease-in;
        opacity: 0;
    }

    25% {
        transform: translateX(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }

    50% {
        transform: translateX(-20px);
        animation-timing-function: ease-in;
    }

    75% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }

    100% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
}

[data-aos="custom-fade-down"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translateY(-100px);

        &.aos-animate {
            transform: translateY(0);
        }
    }
}

[data-aos="custom-fade-up"] {
    opacity: 0;
    transition-property: transform, opacity;

    &.aos-animate {
        opacity: 1;
    }

    @media screen and (min-width: 768px) {
        transform: translateY(100px);

        &.aos-animate {
            transform: translateY(0);
        }
    }
}

[data-aos="deploy-line"] {
    height: 0%;

    &.aos-animate {
        height: 100%;
    }
}
