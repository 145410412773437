#footer {
    padding: 5rem 0;
    background-color: $darkblue;

    @include media-breakpoint-up(md) {
        padding-top: 10rem;
    }


    .icon__container {
        padding: 1rem 1rem;

        img {
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 3rem;

            @include media-breakpoint-up(md) {
                height: 2rem;
            }
        }
    }

    .footer__title {
        text-align: center;
        margin-bottom: 1.5rem;
        color: white;

        @include media-breakpoint-up(md) {
            text-align: start;
            margin-bottom: 0rem;
            display: flex;
            align-items: center;
            height: 100%;
            color: white;
        }
    }
}
