.navbar {
    $navbarHeight: 3rem;

    top: 0;
    left: 0;
    width: 100%;
    z-index: 3000;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
        justify-content: flex-end;
        flex-direction: row;
    }


    &.js-toggle--inactive {
        height: $navbarHeight;

        .navbar__item {
            display: none;

            @include media-breakpoint-up(md) {
                display: initial;
            }
        }
    }

    &.js-toggle--active {
        height: 11rem;

        .navbar__item {
            font-size: 1.5rem;
        }
    }

    &.js-scrolled--inactive {
        position: absolute;
        background-color: #020520;

        .burger__icon span, input:checked ~ span {
            background-color: $yellow;
        }

        .navbar__item {
            color: rgba(202, 202, 202, 0.6);

            &:hover {
                cursor: pointer;
                color: rgba(255, 255, 255, 1);
            }
        }
    }

    &.js-scrolled--active {
        position: fixed;
        background-color: white;
        box-shadow: 0px 2px 3px -1px rgba(255, 255, 255, 0.75);
        transition: height 0.5s;

        .navbar__item {
            color: rgba(0, 0, 0, 0.45);

            &:hover {
                color: rgba(0, 0, 0, 1);
                cursor: pointer;
            }

            &.js-current {
                color: rgba(0, 0, 0, 1);
            }
        }
    }

    .burger__icon {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: $navbarHeight;
        width: $navbarHeight;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
            flex-direction: row;
            display: none;
        }


        input {
            display: block;
            position: absolute;
            height: $navbarHeight;
            width: $navbarHeight;
            cursor: pointer;
            opacity: 0;
            z-index: 60000;
        }

        span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            background-color: $darkblue;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

            &:first-child {
                transform-origin: 0% 0%;
            }

            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
        }

        &.js-toggle--active {
            span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background-color: $darkblue;

                &:nth-last-child(2) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }

                &:nth-last-child(1) {
                    transform: rotate(-45deg) translate(0, -3px);
                }
            }
        }
    }

    .navbar__item {
        font-size: 1.2rem;
        transition: color 0.25s;
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(md) {
            display: initial;
            width: auto;
            margin: 0 1rem;
        }


        &::after {
            width: 0%;
            height: 1px;
            background-color: black;
            display: block;
            position: relative;
            transition: all 0.5s;

            @include media-breakpoint-up(md) {
                content: "";
            }
        }

        &:hover {
            text-decoration: none;

            &::after {
                width: 100%;
            }
        }

        &.js-current::after {
            width: 100%;
        }
    }
}
