#contact {
    background-color: $darkblue;

    form {
        input, textarea {
            background-color: transparent;
            border-color: transparent;
            border-bottom-color: white;
            padding-bottom: 1.3rem;
            margin-bottom: 2rem;
            height: 4rem;
            color: white;

            &[value] {
                color: white;
            }

            &:focus {
                box-shadow: none !important;
            }
        }

        .js-email-control {
            &.valid {
                border-bottom-color: rgba(31, 158, 82, 0.8);
            }

            &.invalid {
                border-bottom-color: rgba(215, 40, 40, 0.8);
            }
        }

        .js-alert-control {
            display: none;

            &.invalid {
                display: flex;
            }

            p {
                color: rgb(215, 40, 40);
                font-size: 1rem;
                padding-left: 1rem;
            }
        }

        .btn--custom {
            padding: 1.3rem 1.2rem;
            border-radius: 5px;
            font-size: 1em;
            width: 100%;
            background-color: transparent;
            border-color: white;
            transition: background-color color  0.6s;
            transition: width  1.5s;

            &.valid {
                border-color: $green;
                color: white;

                &:hover {
                    cursor: pointer;
                    background-color: $green;
                }
            }

            &.success {
                width: 40%;
                background-color: $green;
            }

            &.error {
                background-color: red;
                border-color: red;
            }
        }
    }

    .img__container {
        position: relative;
        width: 80%;

        img {
            width: 100%;
            height: auto;
            z-index: 1;
        }

        .img__clone--yellow {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $yellow;
            left: 1rem;
            top: 1rem;
            z-index: -1;
        }
    }

    h1 {
        padding: 6rem;
    }

    h2 {
        margin-bottom: 1rem;

        &::after {
            margin: 0.5rem 0;
            content: "";
            width: 100%;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.6);
        }
    }

    h3 {
        margin-bottom: 1rem;
    }

    ul {
        margin-bottom: 1rem;
    }
}
